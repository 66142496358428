import React, { useState } from "react";
import { Layout } from "../components/utilityComponents/layout";
import { graphql, StaticQuery } from "gatsby";
import { DocumentWrapper } from "../components/utilityComponents/documentWrapper";
import { Slider } from "../components/menuPageComponents/slider";
import { SeasonalOffer } from "../components/menuPageComponents/seasonalOffer";
import { MenuComponent } from "../components/menuPageComponents/menuComponent";
import { MenuComponentFragment } from "../components/menuPageComponents/menuComponentFragment";
import { Button } from "../components/utilityComponents/button";
import { Collapse } from "reactstrap";
import { MenuTile } from "../components/menuPageComponents/menuTile";

export default function MenuPage() {
  const [isAlko, setIsAlko] = useState(false);

  
  return (
    <StaticQuery
      query={graphql`
        {
          allStrapiMenuType {
            nodes {
              id
              name
              menu_items {
                vegeProduct
                spicyProduct
                superSpicyProduct
                rightTitle
                title
                description
                image {
                  url
                }
                price
                day
              }
            }
          }
          allStrapiMenuCategory {
            nodes {
              title
              accessories
              menu_items {
                vegeProduct
                spicyProduct
                superSpicyProduct
                rightTitle
                title
                description
                image {
                  url
                }
                price
                day
              }
            }
          }
        }
      `}
      render={({ allStrapiMenuType: { nodes }, allStrapiMenuCategory }) => {
        const menuCategories = allStrapiMenuCategory.nodes;

        const weeklyOffer = nodes.find(
          ({ name }: any) => name === "weekly-offer"
        ).menu_items;

        const week = [
          weeklyOffer.find(({ day }: any) => day === "Poniedziałek"),
          weeklyOffer.find(({ day }: any) => day === "Wtorek"),
          weeklyOffer.find(({ day }: any) => day === "Środa"),
          weeklyOffer.find(({ day }: any) => day === "Czwartek"),
          weeklyOffer.find(({ day }: any) => day === "Piątek"),
          weeklyOffer.find(({ day }: any) => day === "Sobota"),
          weeklyOffer.find(({ day }: any) => day === "Niedziela"),
        ];

        const seasonalOffer = nodes.find(
          ({ name }: any) => name === "season-offer"
        ).menu_items;

        return (
          <Layout
            darkBackground
            headerChildren={
              <h1 className="polityka_prywatnosci__header">MENU</h1>
            }
          >
            <div>
              <Slider items={week} />
              <SeasonalOffer items={seasonalOffer} />
              <MenuComponent menuCategories={menuCategories} />
              <MenuComponentFragment
                label="Przystawki"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Przystawki"
                  ).menu_items
                }
              />
              <MenuComponentFragment
                label="Zupy"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Zupy"
                  ).menu_items
                }
              />
              <MenuComponentFragment
                label="Sałatki"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Sałatki"
                  ).menu_items
                }
              />
              <MenuComponentFragment
                label="Ryby i owoce morza"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Ryby i owoce morza"
                  ).menu_items
                }
              />
              <MenuComponentFragment
                label="Pasty"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Pasty"
                  ).menu_items
                }
              />
              <MenuComponentFragment
                label="Dania główne"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Dania główne"
                  ).menu_items
                }
              />
              <MenuComponentFragment
                label="Wołowe specjały"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Wołowe specjały"
                  ).menu_items
                }
                myAccessories={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Wołowe specjały"
                  ).accessories
                }
              />
              <MenuComponentFragment
                label="Pierogi"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Pierogi"
                  )?.menu_items
                }
              />
              <MenuComponentFragment
                label="Desery"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Desery"
                  ).menu_items
                }
              />
              <MenuComponentFragment
                label="Menu dziecięce"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Menu dziecięce"
                  ).menu_items
                }
              />
              <MenuComponentFragment
                label="Dodatki (150g)"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Dodatki (150g)"
                  ).menu_items
                }
              />

              <MenuComponentFragment
                drink
                label="Napoje"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Napoje"
                  ).menu_items
                }
              />
                <MenuComponentFragment
                    drink
                    label="Kawa/Herbata"
                    items={
                        allStrapiMenuCategory.nodes.find(
                            ({ title }: any) => title === "Kawa/Herbata"
                        ).menu_items
                    }
                />
              <MenuComponentFragment
                drink
                label="Lemoniady 400ml"
                items={
                  allStrapiMenuCategory.nodes.find(
                    ({ title }: any) => title === "Lemoniady 400ml"
                  ).menu_items
                }
              />
              <div
                style={{ display: "flex", justifyContent: "center" }}
                id="alko"
              >
                <h1 className="menu__utilityHeader menu__alkoHeader">
                  ALKOHOLE
                </h1>
              </div>
              <Collapse isOpen={!isAlko}>
                <p className="menu__alkoParagraph">
                  dostępne są wyłącznie na miejscu oraz jedynie dla osób
                  pełnoletnich
                </p>
                <div className="menu__btnContainer" style={{ border: "none" }}>
                  <Button
                    onClick={() => setIsAlko(true)}
                    style={{ margin: "0 auto" }}
                    label="Rozumiem"
                    size="medium"
                    type="primary"
                  />
                </div>
              </Collapse>
              <Collapse isOpen={isAlko} id="visibleAlko">
                <MenuComponentFragment
                  drink
                  label="Drinki"
                  items={
                    allStrapiMenuCategory.nodes.find(
                      ({ title }: any) => title === "Drinki"
                    ).menu_items
                  }
                />
                  <MenuComponentFragment
                      drink
                      label="Piwa lane"
                      items={
                          allStrapiMenuCategory.nodes.find(
                              ({ title }: any) => title === "Piwo butelkowe"
                          ).menu_items
                      }
                  />
                  <MenuComponentFragment
                      drink
                      label="Wódki"
                      items={
                          allStrapiMenuCategory.nodes.find(
                              ({ title }: any) => title === "Wódki"
                          ).menu_items
                      }
                  />
                  <MenuComponentFragment
                      drink
                      label="Piwo butelkowe"
                      items={
                          allStrapiMenuCategory.nodes.find(
                              ({ title }: any) => title === "Piwo butelkowe"
                          ).menu_items
                      }
                  />
                  <MenuComponentFragment
                      drink
                      label="Whisky"
                      items={
                          allStrapiMenuCategory.nodes.find(
                              ({ title }: any) => title === "Whisky"
                          ).menu_items
                      }
                  />
                  <MenuComponentFragment
                      drink
                      label="Cognac"
                      items={
                          allStrapiMenuCategory.nodes.find(
                              ({ title }: any) => title === "Cognac"
                          ).menu_items
                      }
                  />
                <MenuComponentFragment
                  drink
                  label="Rum"
                  items={
                    allStrapiMenuCategory.nodes.find(
                      ({ title }: any) => title === "Rum"
                    ).menu_items
                  }
                />
                <MenuComponentFragment
                  drink
                  label="Tequila"
                  items={
                    allStrapiMenuCategory.nodes.find(
                      ({ title }: any) => title === "Tequila"
                    ).menu_items
                  }
                />
                <MenuComponentFragment
                  drink
                  label="Gin"
                  items={
                    allStrapiMenuCategory.nodes.find(
                      ({ title }: any) => title === "Gin"
                    ).menu_items
                  }
                />
                <MenuComponentFragment
                  drink
                  label="Aperityf"
                  items={
                    allStrapiMenuCategory.nodes.find(
                      ({ title }: any) => title === "Aperityf"
                    ).menu_items
                  }
                />
                <MenuComponentFragment
                  drink
                  label="Wina musujące i szampany"
                  items={
                    allStrapiMenuCategory.nodes.find(
                      ({ title }: any) => title === "Wina musujące i szampany"
                    ).menu_items
                  }
                />
                <MenuComponentFragment
                  drink
                  label="Likiery"
                  items={
                    allStrapiMenuCategory.nodes.find(
                      ({ title }: any) => title === "Likiery"
                    ).menu_items
                  }
                />
              </Collapse>
            </div>
          </Layout>
        );
      }}
    />
  );
}
