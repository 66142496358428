import React from "react";
import { Button } from "../utilityComponents/button";
import { Container } from "../../helpers/container";

export const MenuComponent = ({ menuCategories }: any) => {
  console.log(menuCategories);
  return (
    <Container className="menuComponent">
      <div className="menuComponent">
        <div className="menuComponent__header">
          <h3 className="menu__utilityHeader" style={{ margin: "43px 0 20px" }}>
            Menu
          </h3>

          <div className="menuComponent__list">
            {menuCategories.map(({ title }: any) => (
              <>
                {title &&
                ![
                  "Wódki",
                  "Piwa lane",
                  "Piwo butelkowe",
                  "Whisky",
                  "Cognac",
                  "Tequila",
                  "Rum",
                  "Gin",
                  "Aperityf",
                  "Wina musujące i szampany",
                  "Wina musujące i szampany",
                    "Likiery","Wino",
                    "Drinki",
                        'Alkohole mocne'
                ].includes(title) ? (
                  <Button
                    style={{ margin: ".5rem" }}
                    onClick={() => {
                      if (
                        ["Wino", "Piwo", "Alkohole mocne", "Drinki"].includes(
                          title
                        ) &&
                        document!.querySelector("#visibleAlko")!.style
                          .display !== "none"
                      ) {
                        window.location.href = `#alko`;
                      } else window.location.href = `#${encodeURI(title)}`;
                    }}
                    label={title}
                    size="small"
                    type="secondary"
                  />
                ) : null}
              </>
            ))}
            <Button
              style={{ margin: ".5rem" }}
              onClick={() => {
                window.location.href = `#alko`;
              }}
              label="Alkohole"
              size="small"
              type="secondary"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};
